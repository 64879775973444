import { Box, Container } from "@mui/material";


function InactiveAccount() {
    return <Container sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ margin: 'auto' }} >
            El perfil digital se activará en unos momentos.<br></br>
            Inténtelo más tarde.
        </Box>

    </Container>
}

export default InactiveAccount