import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants/Constants";
import { handleError } from "../../utils/utils";
import heroImage from './../../assets/authImage.jpeg'
import LoadingProfile from "../../components/LoaderProfile";
import ErrorScreen from "../../components/ErrorScreen";

function EnterPassword({ match, history }) {
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [found, setFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = match.params.token;

    useEffect(() => {
        getToken();
    }, [token]);

    const getToken = async () => {
        setLoading(true);
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const { data } = await axios.get(
                `${BASE_URL}/api/password-reset/${token}`,
                config
            );
            setFound(data.found);
        } catch (error) {
            console.log("Error", error);
        }
        setLoading(false);
    };

    const handleClick = async (e) => {
        e.preventDefault()
        if (password === confirmPassword) {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };

                const { data } = await axios.post(
                    `${BASE_URL}/api/password-reset/${token}`,
                    { password },
                    config
                );
                toast.success("Actualizado correctamente");
                await delay(3000);
                history.push("/")
            } catch (error) {
                handleError(error)
            }
        } else {
            toast.error("Las contraseñas no coinciden");
        }
    };

    const delay = ms => new Promise(res => setTimeout(res, ms));

    if (loading) return <LoadingProfile />;

    if (!found) return <ErrorScreen />;

    return (
        <div className="auth-container">
            <div className="auth-form">
                <img src={heroImage} className="auth-form-image" />
                <form className="auth-form-content">
                    <h4 className="title__text">Ingresa tu nueva Contraseña</h4>
                    <input placeholder="Nueva Contraseña" type='password' onChange={(e) => setPassword(e.target.value)} />
                    <input placeholder="Confirmar Nueva Contraseña" type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
                    <button className="btn__auth" onClick={handleClick} >Restablecer</button>
                </form>
            </div>
        </div>
    );
}

export default EnterPassword;