import "./Auth.css";
import axios from "axios";
import { toast } from 'react-toastify';
import { handleError } from "../../utils/utils";
import validator from 'validator';
import { useState } from "react";
import { BASE_URL } from "../../Constants/Constants";
import heroImage from './../../assets/authImage.jpeg'
import loloImg from "../../assets/lolo.png"

function ResetPassword() {
  const [email, setEmail] = useState("");

  const handleClick = async (e) => {
    e.preventDefault()
    if (email && validator.isEmail(email)) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/password-reset`,
          { email },
          config
        );
        console.log(data)
        setEmail("")
        toast.success('Verifica tu email');

      } catch (error) {
        console.log(error)
        handleError(error)
      };
    } else {
      toast.error('Agrega un email valido');
    }
  }

  return (
    <div className="auth-container">
      <div className="auth-form">
        <img src={heroImage} className="auth-form-image" />
        <form className="auth-form-content">
        <img src={loloImg} alt="lolo" className="auth-header-img" />
          <h4 className="title__text">Recupera tu Contraseña</h4>
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          <button className="btn__auth" onClick={handleClick}>Recuperar</button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
