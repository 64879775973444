import { useState } from "react";
import { useLoloContext } from "../../Context/appContext";
import heroImg from "../../assets/authImage.jpeg"
import loloImg from "../../assets/lolo.png"
import "../Auth/Auth.css";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { adminLogin } = useLoloContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    adminLogin(email, password);
  };
  return (
    <div className="auth-container">
      <div className="auth-form">
        <img src={heroImg} alt="" className="auth-form-image" />
        <form onSubmit={handleSubmit} className="auth-form-content">
          <img src={loloImg} alt="lolo" className="auth-header-img" />
          <h4 className="title__text">Admin</h4>
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          <input placeholder="Contraseña" type='password' onChange={(e) => setPassword(e.target.value)} />
          <button type="submit" className="btn__auth">Iniciar Sesion</button>
        </form>

      </div>
    </div>
  );
}

export default AdminLogin;
