import { getContactIcon } from "../utils/getContactIcons";


function ContactButtonLink({ index, onEditConatctlLink,contact, link }) {
 

  return (
    <button
      onClick={() => onEditConatctlLink(index)}
      className="editor__button editor__button__small editor__contact__button"
    >
      {getContactIcon(contact)}
    </button>
  );
}

export default ContactButtonLink;
