export default function PlusIcon({size = "large"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size == "small" ? "24" : "36"}
      height={size == "small" ? "24" : "36"}
    >
      <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
    </svg>
  );
}
