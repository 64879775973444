import { toast } from 'react-toastify';
export const handleError = (error) =>{
    const errorlog = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    toast.error(errorlog)
    return errorlog
  }

  export const getContactLink = (type, link) => {
    switch (type) {
      case "Email":
        return `mailto:${link}`;
      case "Telefono":
        return `tel:${link}`;
      case "Compartir":
        return `tel:${link}`;
      case "Whatsapp":
        return `https://wa.me/52${link}`;
      default:
        return "";
    }
  };