import * as React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../Constants/Constants";
import { useLoloContext } from "../../Context/appContext";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Button from '@mui/material/Button';
import { Pagination, Stack, TextField, Typography } from '@mui/material';
import { handleError } from '../../utils/utils';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

function Admin({ match, history }) {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    let field = (new URLSearchParams(window.location.search)).get("field")
    let order = (new URLSearchParams(window.location.search)).get("order")
    const [filterField, setFilterField] = useState(field ?? 'createdAt')
    const [filterOrder, setFilterOrder] = useState(order ?? 'desc')


    const { currentUser, logout } = useLoloContext()
    const keyword = match.params.keyword || '';
    const pageNumber = match.params.pageNumber || 1;


    useEffect(() => {
        getUsers(keyword, pageNumber);
    }, [keyword, pageNumber, field, order]);

    const getUsers = async (keyword = "", pageNumber = "") => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            };
            const { data } = await axios.get(
                `${BASE_URL}/api/users?keyword=${keyword}&pageNumber=${pageNumber}&field=${filterField}&order=${filterOrder}`,
                config
            );
            console.log(data);
            setUsers(data);
        } catch (error) {
            handleError(error)
            console.log(error);
        }
    };

    const handleChange = (event, value) => {
        if (keyword) {
            history.push(`/admin/page/${value}/search/${keyword}`);
        } else {
            history.push(`/admin/page/${value}`);
        }
    };

    const handleSearch = () => {
        if (searchTerm.trim()) {
            history.push(`/admin/search/${searchTerm}`);
        } else {
            history.push('/admin');
        }
    };


    const updateUser = async (id, status) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            };
            const active = {
                active: status ? 2 : 1
            }
            const { data } = await axios.put(
                `${BASE_URL}/api/users/${id}`,
                active,
                config
            );
            await getUsers(keyword, pageNumber);
            toast.success("Actualizado correctamente")
        } catch (error) {
            handleError(error)
            console.log(error);
        }
    }

    function daysRemaining(date) {
        var eventdate = moment(date);
        var todaysdate = moment();
        return eventdate.diff(todaysdate, 'days');
    }

    const parseSubscriptionDate = (date) => {
        if (!date) {
            return "N/A"
        }

        const subscriptionDate = moment(date).format('DD/MM/YY')
        const days = daysRemaining(date)
        const remainingDays = days > 0 ? `${days} dias` : 'Vencido'

        return `${subscriptionDate} / ${remainingDays}`
    }

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleFilter = () => {
        if (match.params.pageNumber) {
            history.push(`/admin/page/${pageNumber}?field=${filterField}&order=${filterOrder}`);
        } else {
            history.push(`/admin?field=${filterField}&order=${filterOrder}`);
        }

    }

    const resetFilter = () =>{
        setFilterField('createdAt')
        setFilterOrder('desc')
        history.push(`/admin/`);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="space-between" p={1} m={0}>
                    <Box p={1}>
                        <Typography variant="h4" gutterBottom>
                            Administrador
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" p={1} m={0}>
                        <Box p={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={logout}
                            >
                                Salir
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" p={1} m={0}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            id="basic-button"
                            aria-controls={openMenu ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={handleOpenMenu}
                        >
                            Filtro
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <Box columnGap={2} display="flex" justifyContent="space-between" px={2} py={1}  >
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Campo</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filterField}
                                        label="Campo"
                                        onChange={(e) => setFilterField(e.target.value)}
                                    >
                                        <MenuItem value={'createdAt'}>Fecha</MenuItem>
                                        <MenuItem value={'subscriptionDate'}>Subscripcion</MenuItem>
                                        <MenuItem value={'name'}>Nombre</MenuItem>
                                        <MenuItem value={'email'}>Email</MenuItem>
                                        <MenuItem value={'company'}>Empresa</MenuItem>
                                        <MenuItem value={'cardColor'}>Color</MenuItem>
                                        <MenuItem value={'category'}>Categoria</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Orden</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={filterOrder}
                                        onChange={(e) => setFilterOrder(e.target.value)}
                                    >
                                        <FormControlLabel value="asc" control={<Radio />} label="Ascendente" />
                                        <FormControlLabel value="desc" control={<Radio />} label="Descendente" />
                                    </RadioGroup>
                                </FormControl>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleFilter}
                                    >
                                        Aplicar
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={resetFilter}
                                    >
                                        Restablecer
                                    </Button>
                                </Box>
                            </Box>
                        </Menu>
                    </div>
                    <Box display="flex">
                        <TextField
                            placeholder="Buscar"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            inputProps={{ "aria-label": "search" }}
                        />
                        <Box p={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Buscar
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell align="left">Nombre</TableCell>
                                <TableCell align="left">Apellido</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Empresa</TableCell>
                                <TableCell align="left">Categoria</TableCell>
                                <TableCell align="left">Color</TableCell>
                                <TableCell align="left">Vencimiento</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.users?.map((user) => (
                                <TableRow
                                    key={user._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {moment(user.createdAt).format('DD/MM/YY')}
                                    </TableCell>
                                    <TableCell align="left">{user.name}</TableCell>
                                    <TableCell align="left">{user.lastname}</TableCell>
                                    <TableCell align="left">{user.email}</TableCell>
                                    <TableCell align="left">{user.company}</TableCell>
                                    <TableCell align="left">{user.category}</TableCell>
                                    <TableCell align="left">{user.cardColor}</TableCell>
                                    <TableCell align="left">{parseSubscriptionDate(user.subscriptionDate)}</TableCell>
                                    <TableCell align="left"><Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateUser(user._id, user.active)}
                                    >
                                        {user.active ? 'Desactivar' : 'Activar'}
                                    </Button></TableCell>
                                    <TableCell align="left">
                                        <Button href={`/${user._id}`} target='_blank'>Ver</Button>
                                        <Button onClick={() => history.push(`/admin/user/${user._id}`)} target='_blank'>Editar</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    component="div"
                    sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            count={users.pages}
                            page={parseInt(pageNumber)}
                            onChange={handleChange}
                        />
                    </Stack>
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Admin;
