import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ResetPassword from "./pages/Auth/ResetPassword";
import Editor from "./pages/Editor/Editor";
import Profile from "./pages/Profile/Profile";
import { LoloProvider } from "./Context/appContext";
import AuthRoute from "./components/AuthRoute";
import AuthAdminRoute from "./components/AuthAdminRoute";
import PrivarteAdminRoute from "./components/PrivarteAdminRoute";
import PrivateRoute from "./components/PrivateRoute";
import Admin from "./pages/Admin/Admin";
import AuthAdmin from "./pages/Admin/Auth";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Preview from "./pages/Profile/Preview";
import UserDetailed from "./pages/Admin/UserDetailed";
import EnterPassword from "./pages/Auth/EnterPassword";


function App() {
  return (
    <div>
      <Router>
        <LoloProvider>
          <Switch>
            <PrivateRoute path="/" component={Editor} exact />
            <PrivarteAdminRoute path="/admin" component={Admin} exact />
            <AuthAdminRoute path="/auth/admin" component={AuthAdmin} exact />
            <PrivarteAdminRoute
              path="/admin/search/:keyword"
              component={Admin}
              exact
            />
            <PrivarteAdminRoute
              path="/admin/page/:pageNumber"
              component={Admin}
              exact
            />
            <PrivarteAdminRoute
              path="/admin/search/:keyword/page/:pageNumber"
              component={Admin}
              exact
            />
            <PrivarteAdminRoute path="/admin/user/:id" component={UserDetailed} exact />
            <AuthRoute path="/login" component={Login} exact />
            <AuthRoute path="/signup" component={Signup} exact />
            <AuthRoute path="/reset-password" component={ResetPassword} exact />
            <AuthRoute path="/new-password/:token" component={EnterPassword} />
            <PrivateRoute path="/editor" component={Editor} exact />
            <PrivateRoute path="/preview" component={Preview} exact />
            <Route path="/:id" component={Profile} exact />
          </Switch>
        </LoloProvider>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
