import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../Constants/Constants";
import { handleError } from "../utils/utils"

const LoloContext = React.createContext();

export function useLoloContext() {
  return useContext(LoloContext);
}

export function LoloProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [errors, setErrors] = useState(null);

  const register = async (name, email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/users`,
        { name, email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("loloUser", JSON.stringify(data));
    } catch (error) {
      handleError(error);
    }
  };

  const login = async (email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/users/login`,
        { email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("loloUser", JSON.stringify(data));
    } catch (error) {
      handleError(error)
      //handleErrors(error);
    }
  };

  const adminLogin = async (email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/admin/login`,
        { email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("loloUser", JSON.stringify(data));
    } catch (error) {
      handleError(error);
    }
  };

  const logout = async () => {
    if (localStorage.getItem("loloUser")) {
      localStorage.removeItem("loloUser");
    }
    setCurrentUser(null);
  };


  const handleErrors = (error) =>{
    const errorlog = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    console.log(errorlog)
    setErrors(errorlog);
  }

  useEffect(() => {
    if (localStorage.getItem("loloUser")) {
      const user = JSON.parse(localStorage.getItem("loloUser"));
      const decodedToken = jwtDecode(user.token);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("loloUser");
      } else {
        setCurrentUser(user);
      }
    }
    setLoading(false);
  }, []);

  const value = {
    currentUser,
    register,
    login,
    adminLogin,
    logout,
    errors
  };
  return (
    <LoloContext.Provider value={value}>
      {!loading && children}
    </LoloContext.Provider>
  );
}