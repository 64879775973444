import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { BottomSheet } from "react-spring-bottom-sheet";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { v4 as uuid } from "uuid";
import ReactPlayer from "react-player";
import { canvasPreview } from "../../components/canvasPreview";
import { imgPreview, imgBlob } from "../../components/imagePreview";
import { useDebounceEffect } from "../../utils/useDebounceEffect";
import PlusIcon from "../../icons/util/plus";
import ClipIcon from "../../icons/util/clip";
import EditIcon from "../../icons/util/edit";
import CamaraIcon from "../../icons/util/camara";
import HelpButton from "../../components/helpButton";
import ContactButtonLink from "../../components/contactButtonLink";
import SocialButtonLink from "../../components/socialButtonLink";
import { useLoloContext } from "../../Context/appContext";
import { BASE_URL } from "../../Constants/Constants";
import "./Editor.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-spring-bottom-sheet/dist/style.css";
import { handleError } from "../../utils/utils";
import { toast } from 'react-toastify';
import validator from 'validator';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CssBaseline } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import loloPortada from "../../assets/portada.png"
import loloAvatar from "../../assets/avatar.jpg"

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function Editor({ match, history }) {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("Benjamin");
  const [lastname, setLAstname] = useState("Landavazo");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("Flutter Developer");
  const [company, setCompany] = useState("Solunatec");
  const [bio, setBio] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut lectus et diam iaculis suscipit. Sed convallis molestie dui, nec porta dui auctor vitae. Curabitur condimentum, mauris a viverra dictum."
  );
  const [selctedTab, sertSelectedTab] = useState("Negocios");

  /**********Portada Image */
  const [portada, setPortada] = useState(
    "https://vc.taplolo.mx/lolo-assets/images/jorge-tapia-portada.png"
  );

  /**********Avatar Image */
  const [avatar, setAvatar] = useState(
    "https://vc.taplolo.mx/lolo-assets/images/jorge-tapia-avatar.jpeg"
  );

  /**********Image Cropper */
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(16 / 9);
  const [openCropper, setOpenCropper] = useState(false);

  /**********Contact PHONE */
  const [contactPhone, setContactPhone] = useState("");
  const [openContactPhone, setOpenContactPhone] = useState(false);

  /**********Contact LINKS */
  const [contactLinks, setContactLinks] = useState([]);
  const [openContact, setOpenContact] = useState(false);
  const [editContact, setEditContact] = useState(-1);
  const [contactName, setContactName] = useState("");
  const [contactLink, setContactLink] = useState("");

  /**********SOCIAL LINKS */
  const [socialLinks, setSocialLinks] = useState([]);
  const [openSocial, setOpenSocial] = useState(false);
  const [editSocial, setEditSocial] = useState(-1);
  const [socialName, setSocialName] = useState("");
  const [socialType, setSocialType] = useState("");
  const [socialLink, setSocialLink] = useState("");

  /**********CUSTOM LINKS */
  const [customLinks, setCustomLinks] = useState([]);
  const [openCustomLinks, setOpenCustomLinks] = useState(false);
  const [editCustom, setEditCustom] = useState(-1);
  const [customLinkName, setCustomLinkName] = useState("");
  const [customLink, setCustomLink] = useState("");

  /**********TAGS LINKS */
  const [tagLinks, setTagLinks] = useState([]);
  const [openTagLinks, setOpenTagLinks] = useState(false);
  const [tagLinkName, setTagLinkName] = useState("");

  /**********GALLERY LINKS */
  const [galleryLinks, setGalleryLinks] = useState([]);
  const galleryRef = useRef(null);

  /**********VIDEO LINKS */
  const [videoLinks, setVideoLinks] = useState([]);
  const [openVideoLinks, setOpenVideoLinks] = useState(false);
  const [editVideo, setEditVideo] = useState(-1);
  const [videoLinkName, setVideoLinkName] = useState("");
  const [videoLink, setVideoLink] = useState("");

  /**********ATTACHMENTS LINKS */
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [openAttachmentLinks, setOpenAttachmentLinks] = useState(false);
  const [editAttachment, setEditAttachment] = useState(-1);
  const [attachmentLinkName, setAttachmentLinkName] = useState("");
  const [attachmentLink, setAttachmentLink] = useState("");

  const avatarToastId = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogLoading, setOpenDialogLoading] = useState(false);

  const { currentUser, logout } = useLoloContext();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.get(`${BASE_URL}/api/users/data/me`, config);
      setName(data.name);
      setLAstname(data.lastname);
      setEmail(data.email);
      setPosition(data.position);
      setBio(data.bio);
      setCompany(data.company);
      setContactPhone(data.contactPhone)
      if(data.portada === '/api/uploads/portada.png')
        setPortada(loloPortada);
      else
        setPortada(data.portada);
      if(data.avatar === '/api/uploads/avatar.png')
        setAvatar(loloAvatar)
      else
        setAvatar(data.avatar);
      setContactLinks(data.contactLinks);
      setSocialLinks(data.socialLinks);
      setCustomLinks(data.customLinks);
      setTagLinks(data.tagLinks);
      setGalleryLinks(data.galleryLinks);
      setVideoLinks(data.videoLinks);
      setAttachmentLinks(data.attachmentLinks);
      setUser(data);
      console.log("Me", data);
    } catch (error) {
      handleError(error)
    }
  };


  const update = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          name,
          lastname,
          email,
          position,
          bio,
          company,
          contactPhone,
          portada,
          avatar,
          contactLinks,
          socialLinks,
          customLinks,
          tagLinks,
          galleryLinks,
          videoLinks,
          attachmentLinks
        },
        config
      );
      setUser(data);
      toast.success("Actualizado Correctamente")
    } catch (error) {
      handleError(error)
    }
  }

  const updateImages = async (image) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        image,
        config
      );
      if(data.portada === '/api/uploads/portada.png')
        setPortada(loloPortada);
      else
        setPortada(data.portada);
      if(data.avatar === '/api/uploads/avatar.png')
        setAvatar(loloAvatar)
      else
        setAvatar(data.avatar);
      setGalleryLinks(data.galleryLinks);
      toast.success("Actualizado Correctamente")
    } catch (error) {
      handleError(error)
    }
  }

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      update();
    },
    // delay in ms
    2500
  );

  const onSaveContact = () => {
    if (contactName && contactLink) {
      if((!isFinite(contactLink) || contactLink.length != 10) && contactName !== 'Email'){
        toast.error("El contacto debe ser númerico y tener 10 dígitos")
      }else{
        if (editContact != -1) {
          const data = [...contactLinks];
          data[editContact].contactName = contactName;
          data[editContact].contactLink = contactLink;
          setContactLinks(data);
        } else {
          setContactLinks((oldArray) => [
            ...oldArray,
            { contactName, contactLink },
          ]);
        }
        setContactLink("");
        setContactName("");
        setOpenContact(false);
        debounced();
      }
    } else {                                                                      
      toast.error("Llena todos los campos");
    }
  };

  const onEditConatctlLink = (index) => {
    setContactLink(contactLinks[index].contactLink);
    setContactName(contactLinks[index].contactName);
    setEditContact(index);
    setOpenContact(true);
    debounced();
  };

  const onDeleteContactLink = () => {
    const data = contactLinks.filter((value, index) => editContact != index);
    setContactLinks(data);
    setContactLink("");
    setContactName("");
    setEditContact(-1);
    setOpenContact(false);
    debounced();
  };

  const onSaveSocialLink = () => {
    if (socialName && socialType && socialLink) {
      if (!validator.isURL(socialLink)) {
        toast.error("Agrega un link valido");
        return
      }
      const socials = socialLinks.filter(
        (social) =>
          social.socialName === socialName && social.socialType === socialType
      );
      if (socials.length > 0) {
        toast.error("Solo un tipo de cada uno");
      } else {
        if (editSocial != -1) {
          const data = [...socialLinks];
          data[editSocial].socialName = socialName;
          data[editSocial].socialType = socialType;
          data[editSocial].socialLink = socialLink;
          setSocialLinks(data);
        } else {
          setSocialLinks((oldArray) => [
            ...oldArray,
            { socialName, socialType, socialLink },
          ]);
        }
        setSocialLink("");
        setSocialName("");
        setSocialType("");
        setOpenSocial(false);
        debounced();
      }
    } else {
      toast.error("Llena todos los campos");
    }
  };

  const onEditSocialLink = (id) => {
    const index = socialLinks.findIndex((social) => social._id === id);
    setSocialLink(socialLinks[index].socialLink);
    setSocialName(socialLinks[index].socialName);
    setSocialType(socialLinks[index].socialType);
    setEditSocial(index);
    setOpenSocial(true);
    debounced();
  };

  const onDeleteSocialLink = () => {
    const data = socialLinks.filter((value, index) => editSocial != index);
    setSocialLinks(data);
    setSocialLink("");
    setSocialName("");
    setSocialType("");
    setEditSocial(-1);
    setOpenSocial(false);
    debounced();
  };

  const onAddCustomLink = () => {
    if (customLinkName && customLink) {
      if (!validator.isURL(customLink)) {
        toast.error("Agrega un link valido");
        return
      }

      if (editCustom != -1) {
        const data = [...customLinks];
        data[editCustom].customLinkName = customLinkName;
        data[editCustom].customLink = customLink;
        setCustomLinks(data);
      } else {
        setCustomLinks((oldArray) => [
          ...oldArray,
          { customLinkName, customLink },
        ]);
      }
      setCustomLink("");
      setCustomLinkName("");
      setOpenCustomLinks(false);
      debounced();
    } else {
      toast.error("Llena todos los campos");
    }
  };

  const onEditCustomLink = (index) => {
    setCustomLink(customLinks[index].customLink);
    setCustomLinkName(customLinks[index].customLinkName);
    setEditCustom(index);
    setOpenCustomLinks(true);
    debounced();
  };

  const onDeleteCustomLink = () => {
    const data = customLinks.filter((value, index) => editCustom != index);
    setCustomLinks(data);
    setCustomLink("");
    setCustomLinkName("");
    setEditCustom(-1);
    setOpenCustomLinks(false);
    debounced();
  };

  const onAddTag = () => {
    if (tagLinkName) {
      setTagLinks((oldArray) => [...oldArray, { tagLinkName }]);
      setTagLinkName("");
      setOpenTagLinks(false);
      debounced();
    } else {
      toast.error("Llena todos los campos");
    }
  };

  const onDeleteTag = (tagindex) => {
    const data = tagLinks.filter((value, index) => tagindex != index);
    setTagLinks(data);
    setTagLinkName("");
    debounced();
  };

  const handleAddToGallery = async (e) => {
    handleClickOpenDialogLoading()
    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    //if (fileSize <= 10) {
      const formData = new FormData();
      formData.append("image", file);
      //const imgUrl = URL.createObjectURL(file);
      //setGalleryLinks((oldArray) => [...oldArray, { galleryLink: imgUrl }]);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/uploads`,
          formData,
          config,
        );
        const url = `${BASE_URL}/api${data}`;
        //toast.done(avatarToastId.current);
        //setAvatar(url);
        //debounced()
        const images = { galleryLinks: [...galleryLinks, { galleryLink: url }] }
        await updateImages(images)
      } catch (error) {
        handleError(error)
        handleCloseDialogLoading()
      }
      handleCloseDialogLoading()
    /*} else {
      toast.error("Imagen demasiado grande")
    }*/
  };

  const onDeleteGalleryImg = (galleryindex) => {
    const data = galleryLinks.filter((value, index) => galleryindex != index);
    setGalleryLinks(data);
    debounced();
  };

  const onAddVideoLink = () => {
    if (videoLink) {
      if (!validator.isURL(videoLink)) {
        toast.error("Agrega un link valido");
        return
      }
      if (editVideo != -1) {
        const data = [...videoLinks];
        data[editVideo].videoLinkName = videoLinkName;
        data[editVideo].videoLink = videoLink;
        setVideoLinks(data);
      } else {
        setVideoLinks((oldArray) => [
          ...oldArray,
          { videoLinkName, videoLink },
        ]);
      }

      setVideoLink("");
      setVideoLinkName("");
      setOpenVideoLinks(false);
      debounced();
    } else {
      toast.error("Llena todos los campos");
    }
  };

  const onEditVideoLink = (index) => {
    setVideoLink(videoLinks[index].videoLink);
    setVideoLinkName(videoLinks[index].videoLinkName);
    setEditVideo(index);
    setOpenVideoLinks(true);
    debounced();
  };

  const onDeleteVideoLink = () => {
    const data = videoLinks.filter((value, index) => editVideo != index);
    setVideoLinks(data);
    setVideoLink("");
    setVideoLinkName("");
    setEditVideo(-1);
    setOpenVideoLinks(false);
    debounced();
  };

  const onAddAttachmentLink = () => {
    if (attachmentLinkName && attachmentLink) {
      if (!validator.isURL(attachmentLink)) {
        toast.error("Agrega un link valido");
        return
      }
      if (editAttachment != -1) {
        const data = [...attachmentLinks];
        data[editAttachment].attachmentLinkName = attachmentLinkName;
        data[editAttachment].attachmentLink = attachmentLink;
        setAttachmentLinks(data);
      } else {
        setAttachmentLinks((oldArray) => [
          ...oldArray,
          { attachmentLinkName, attachmentLink },
        ]);
      }

      setAttachmentLink("");
      setAttachmentLinkName("");
      setOpenAttachmentLinks(false);
      debounced();
    } else {
      toast.error("Llena todos los campos");
    }
  };

  const onEditAttachmentLink = (index) => {
    setAttachmentLink(attachmentLinks[index].attachmentLink);
    setAttachmentLinkName(attachmentLinks[index].attachmentLinkName);
    setEditAttachment(index);
    setOpenAttachmentLinks(true);
    debounced();
  };

  const onDeleteAttachmentLink = () => {
    const data = attachmentLinks.filter(
      (value, index) => editAttachment != index
    );
    setAttachmentLinks(data);
    setAttachmentLink("");
    setAttachmentLinkName("");
    setEditAttachment(-1);
    setOpenAttachmentLinks(false);
    debounced();
  };

  const onAddContactPhone = () => {
    if (contactPhone) {
      setOpenContactPhone(false)
      debounced()
    } else {
      toast.error("Agruegue un telefono valido");
    }
  }

  const savePortada = async () => {
    handleClickOpenDialogLoading()
    const previewSrc = await imgPreview(
      imgRef.current,
      completedCrop,
      scale,
      0
    );
    const myBlob = await imgBlob(
      imgRef.current,
      completedCrop,
      scale,
      0
    );
    const myFile = new File([myBlob], 'image.jpeg', {
      type: myBlob.type,
    });
    if (aspect == 1) {
      //setAvatar(previewSrc);
      await uploadAvatarImg(myFile)
    } else {
      //setPortada(previewSrc);
      await uploadPortadaImg(myFile)
    }
    handleCloseDialogLoading()
    imgRef.current = null;
    setImgSrc(null);
    //setCompletedCrop(null);
    setOpenCropper(false);

  };

  function onSelectPortada(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  const uploadAvatarImg = async (file) => {
    const fileSize = file.size / 1024 / 1024;
    //if (fileSize <= 10) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/uploads`,
          formData,
          config,
        );
        const url = `${BASE_URL}/api${data}`;
        //toast.done(avatarToastId.current);
        //setAvatar(url);
        //debounced()
        const image = {
          avatar: url
        }
        await updateImages(image)
      } catch (error) {
        handleError(error)
      }
    /*} else {
      toast.error("Imagen demasiado grande")
    }*/
  };

  const uploadPortadaImg = async (file) => {
    const fileSize = file.size / 1024 / 1024;
    //if (fileSize <= 10) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/uploads`,
          formData,
          config
        );
        //setPortada(`${BASE_URL}/api${data}`);
        //debounced()
        const url = `${BASE_URL}/api${data}`;
        const image = {
          portada: url
        }
        await updateImages(image)
      } catch (error) {
        handleError(error)
      }
    /*} else {
      toast.error("Imagen demasiado grande")
    }*/
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialogLoading = () => {
    setOpenDialogLoading(true);
  };

  const handleCloseDialogLoading = () => {
    setOpenDialogLoading(false);
  };



  const isTwoTypeSocial =
    socialLinks.filter(
      (ele, ind) =>
        ind ===
        socialLinks.findIndex((social) => social.socialType === ele.socialType)
    ).length > 1;

  if (!user) return <div>Cargando</div>
  return (
    <div>
      <CssBaseline />
      <div className="editor">
        <div className="editor__container grid">
          <div className="editor__portada">
            <div style={{position: 'relative'}}
              onClick={() => {
                setAspect(16 / 9);
                setOpenCropper(true);
              }}
            >
              <div
                className="editor__button editor__button__small editor__contact__button" style={{backgroundColor:'gray', borderRadius: '50%', position: 'absolute', bottom: '5%',right: '5%'}}
              >
                <CamaraIcon /></div>
              <img src={portada} alt="" className="editor__portada__img" />
            </div>
            <div className="editor__data">
              <div style={{position: 'relative'}}
                onClick={() => {
                  setAspect(1 / 1);
                  setOpenCropper(true);
                }}
                className="editor__perfil"
              >
                <div
                className="editor__button editor__button__small editor__contact__button" style={{backgroundColor:'gray', borderRadius: '50%', position: 'absolute', bottom: '5%',right: '5%', padding: '10px'}}
              >
                <CamaraIcon size="small" /></div>
                <img src={avatar} className="editor__avatar" alt="" />
              </div>
              <div className="editor__profile__text__container">
                <input
                  className="editor__name"
                  value={name}
                  type="text"
                  maxLength="20"
                  onChange={async (e) => {
                    await setName(e.target.value)
                    debounced();
                  }}
                />
                <input
                  className="editor__name"
                  value={lastname}
                  type="text"
                  maxLength="20"
                  onChange={async (e) => {
                    await setLAstname(e.target.value)
                    debounced();
                  }}
                />
                {/*<h3 className="editor__profession">Software developer</h3>*/}
                <input
                  className="editor__profession"
                  value={position}
                  type="text"
                  maxLength="20"
                  onChange={async (e) => {
                    await setPosition(e.target.value)
                    debounced();
                  }}
                />
                <input
                  className="editor__company"
                  value={company}
                  type="text"
                  maxLength="20"
                  onChange={async (e) => {
                    await setCompany(e.target.value)
                    debounced();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="editor__body__container grid">
            <div className="editor__info grid">
              <textarea
                className="editor__info-description"
                value={bio}
                onChange={async (e) => {
                  await setBio(e.target.value)
                  debounced();
                }}
                cols="60"
                rows="5"
              />
            </div>
            <a
              onClick={() => setOpenContactPhone(true)}
              className="editor__button editor__button__download"
            >
              <PlusIcon size="small" /> Guardar Contacto
            </a>
            <div className="editor__buttons">
              {contactLinks.map((contact, index) => {
                return (
                  <ContactButtonLink
                    key={index}
                    index={index}
                    onEditConatctlLink={onEditConatctlLink}
                    contact={contact.contactName}
                    link={contact.contactLink}
                  />
                );
              })}
              <a
                onClick={() => {
                  setContactLink("");
                  setContactName("");
                  setEditContact(-1);
                  setOpenContact(true);
                }}
                className="editor__button editor__button__small editor__contact__button"
              >
                <PlusIcon />
              </a>
            </div>

            <div className="editor__links__container grid">
              <div style={{ margin: socialLinks.length > 0 ? "0 auto" : "0" }}>
                {socialLinks.length > 0 ? (
                  isTwoTypeSocial ? (
                    <div className="tabConatiner">
                      <div className="tabButtonsWrapper">
                        <button
                          className={`tabButton ${selctedTab == "Negocios" && "selectedTab"
                            }`}
                          onClick={() => sertSelectedTab("Negocios")}
                        >
                          <span>Negocios</span>
                        </button>
                        <button
                          className={`tabButton ${selctedTab == "Personal" && "selectedTab"
                            }`}
                          onClick={() => sertSelectedTab("Personal")}
                        >
                          <span>Personal</span>
                        </button>
                      </div>
                      <div className="tabContent">
                        <div className="editor__buttons">
                          <div className="editor__buttons-small">
                            {socialLinks
                              .filter((soc) => soc.socialType == selctedTab)
                              .map((social, index) => {
                                return (
                                  <SocialButtonLink
                                    key={index}
                                    id={social._id}
                                    onEditSocialLink={onEditSocialLink}
                                    social={social.socialName}
                                    link={social.socialLink}
                                  />
                                );
                              })}
                            <button
                              onClick={() => {
                                setSocialLink("");
                                setSocialName("");
                                setSocialType("");
                                setEditSocial(-1);
                                setOpenSocial(true);
                              }}
                              className="editor__button editor__button__small editor__button__gray"
                            >
                              <PlusIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="editor__buttons">
                      <div className="editor__buttons-small">
                        {socialLinks.map((social, index) => {
                          return (
                            <SocialButtonLink
                              key={index}
                              id={social.id}
                              onEditSocialLink={onEditSocialLink}
                              social={social.socialName}
                              link={social.socialLink}
                            />
                          );
                        })}
                        <button
                          onClick={() => {
                            setSocialLink("");
                            setSocialName("");
                            setSocialType("");
                            setEditSocial(-1);
                            setOpenSocial(true);
                          }}
                          className="editor__button editor__button__small editor__button__gray"
                        >
                          <PlusIcon />
                        </button>
                      </div>
                    </div>
                  )
                ) : (
                  <HelpButton
                    text={"+ Redes Sociales"}
                    onClick={() => setOpenSocial(true)}
                  />
                )}
              </div>
              <div>
                {customLinks.length > 0 ? (
                  <>
                    <h3 className="editor__info__title">Enlaces</h3>
                    <div className="editor__links__container grid">
                      {customLinks.map((link, index) => {
                        return (
                          <a
                            onClick={() => onEditCustomLink(index)}
                            className="editor__button editor__custom__links"
                          >
                            {link.customLinkName}
                          </a>
                        );
                      })}
                      <a
                        onClick={() => {
                          setCustomLink("");
                          setCustomLinkName("");
                          setEditCustom(-1);
                          setOpenCustomLinks(true);
                        }}
                        className="editor__button editor__custom__links"
                      >
                        <PlusIcon size="small" /> Agregar
                      </a>
                    </div>
                  </>
                ) : (
                  <HelpButton
                    text={"+ Enlaces"}
                    onClick={() => {
                      setCustomLink("");
                      setCustomLinkName("");
                      setEditCustom(-1);
                      setOpenCustomLinks(true);
                    }}
                  />
                )}
              </div>
              <div>
                {tagLinks.length > 0 ? (
                  <div className="editor__tag__container">
                    {tagLinks.map((tag, index) => (
                      <div className="editor__tag">
                        {tag.tagLinkName}
                        <button onClick={() => onDeleteTag(index)}>x</button>
                      </div>
                    ))}
                    <div
                      className="editor__tag editor__tag__add"
                      onClick={() => setOpenTagLinks(true)}
                    >
                      + Agregar
                    </div>
                  </div>
                ) : (
                  <HelpButton
                    text={"+ Conexiones"}
                    onClick={() => {
                      setOpenTagLinks(true);
                    }}
                  />
                )}
              </div>
              <div>
                {galleryLinks.length > 0 ? (
                  <>
                    <h3 className="editor__info__title">Galeria</h3>
                    <div className="editor__links__container editor__gallery__wrapper grid">
                      {galleryLinks.map((link, index) => {
                        return (
                          <div className="editor__gallery__img__wrapper">
                            <img
                              className="editor__gallery__img"
                              src={link.galleryLink}
                            />
                            <span
                              onClick={() => onDeleteGalleryImg(index)}
                              className="editor__gallery__delete"
                            >
                              X
                            </span>
                          </div>
                        );
                      })}
                      {galleryLinks.length < 3 && (
                        <a
                          onClick={() => galleryRef.current.click()}
                          className="editor__button editor__custom__links editor__gallery__img"
                        >
                          <PlusIcon size="small" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  <HelpButton
                    text={"+ Galeria"}
                    onClick={() => galleryRef.current.click()}
                  />
                )}
                <input
                  onChange={handleAddToGallery}
                  multiple={false}
                  ref={galleryRef}
                  type="file"
                  hidden
                />
              </div>
              <div>
                {videoLinks.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <h3 className="editor__info__title">Videos</h3>
                      <div>
                        <span
                          className="editor__icon__button"
                          onClick={() => onEditVideoLink(0)}
                        >
                          <EditIcon size="small" />{" "}
                        </span>
                      </div>
                    </div>
                    <div className="editor__links__container grid">
                      {videoLinks.map((link, index) => {
                        return (
                          <div style={{ margin: "0 auto" }}>
                            <ReactPlayer
                              height={220}
                              width={320}
                              url={link.videoLink}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <HelpButton
                    text={"+ Videos"}
                    onClick={() => {
                      setVideoLink("");
                      setVideoLinkName("");
                      setEditVideo(-1);
                      setOpenVideoLinks(true);
                    }}
                  />
                )}
              </div>
              <div>
                {attachmentLinks.length > 0 ? (
                  <>
                    <h3 className="editor__info__title">Adjuntos</h3>
                    <div className="editor__links__container grid">
                      {attachmentLinks.map((link, index) => {
                        return (
                          <a
                            onClick={() => onEditAttachmentLink(index)}
                            className="editor__button editor__custom__links editor__attach__button"
                          >
                            <ClipIcon size="small" /> {link.attachmentLinkName}
                          </a>
                        );
                      })}
                      <a
                        onClick={() => {
                          setAttachmentLink("");
                          setAttachmentLinkName("");
                          setEditAttachment(-1);
                          setOpenAttachmentLinks(true);
                        }}
                        className="editor__button editor__custom__links editor__attach__button"
                      >
                        <PlusIcon size="small" /> Agregar
                      </a>
                    </div>
                  </>
                ) : (
                  <HelpButton
                    text={"+ Adjuntos"}
                    onClick={() => {
                      setAttachmentLink("");
                      setAttachmentLinkName("");
                      setEditAttachment(-1);
                      setOpenAttachmentLinks(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <BottomSheet
            onDismiss={() => {
              imgRef.current = null;
              setImgSrc(null);
              setOpenCropper(false);
            }}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight]}
            open={openCropper}
          >
            <div className="bottom__sheet__container">
              <h3 className="editor__info__title">
                {aspect == 1 ? "Imagen de perfil" : "Imagen de portada"}
              </h3>
              <div>
                <div>
                  <input
                    type="file"
                    id="file-upload"
                    // ref={imgRef}
                    hidden
                    accept="image/jpeg,image/png"
                    onChange={onSelectPortada}
                  />
                </div>
                {Boolean(imgSrc) && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        transform: `scale(${scale}) rotate(0 deg)`,
                        maxWidth: "300px"
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
              </div>
              {imgSrc ? (
                <button onClick={savePortada} className="editor__save__btn">
                  Guardar
                </button>
              ) : (
                <label htmlFor="file-upload" className="editor__save__btn">
                  Elegir
                </label>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenContact(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openContact}
          >
            <div className="bottom__sheet__container">
              <select
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                className="editor__form__input"
              >
                <option value="">Elige</option>
                {["Whatsapp", "Telefono", "Email"]
                  .filter(
                    (o1) => !contactLinks.some((o2) => o1 === o2.contactName)
                  )
                  .map((item) => (
                    <option value={item}>{item}</option>
                  ))}
              </select>
              <input
                className="editor__form__input"
                value={contactLink}
                onChange={(e) => setContactLink(e.target.value)}
                placeholder={contactName == "Email" ? "Email" : "Numero"}
              />
              <button onClick={onSaveContact} className="editor__save__btn">
                {editContact == -1 ? "Guardar" : "Guardar cambios"}
              </button>
              {editContact !== -1 && (
                <button
                  onClick={onDeleteContactLink}
                  className="editor__save__btn"
                >
                  Eliminar
                </button>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenSocial(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openSocial}
          >
            <div className="bottom__sheet__container">
              <h3>Redes Sociales</h3>
              <select
                value={socialName}
                onChange={(e) => setSocialName(e.target.value)}
                className="editor__form__input"
              >
                <option value="">Elige</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="Youtube">Youtube</option>
                <option value="Linked In">Linked In</option>
                <option value="Tik Tok">Tik Tok</option>
                <option value="Snapchat">Snapchat</option>
                <option value="Reddit">Reddit</option>
                <option value="Pinterest">Pinterest</option>
                <option value="Telegram">Telegram</option>
                <option value="Spotify">Spotify</option>
                <option value="Flickr">Flickr</option>
              </select>
              <select
                value={socialType}
                onChange={(e) => setSocialType(e.target.value)}
                className="editor__form__input"
              >
                <option value="">Tipo</option>
                <option value="Negocios">Negocios</option>
                <option value="Personal">Personal</option>
              </select>
              <input
                className="editor__form__input"
                value={socialLink}
                onChange={(e) => setSocialLink(e.target.value)}
                placeholder="Link"
              />
              <button onClick={onSaveSocialLink} className="editor__save__btn">
                {editSocial == -1 ? "Guardar" : "Guardar cambios"}
              </button>
              {editSocial !== -1 && (
                <button
                  onClick={onDeleteSocialLink}
                  className="editor__save__btn"
                >
                  Eliminar
                </button>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenCustomLinks(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openCustomLinks}
          >
            <div className="bottom__sheet__container">
              <h3>Enlaces</h3>
              <input
                className="editor__form__input"
                value={customLinkName}
                onChange={(e) => setCustomLinkName(e.target.value)}
                placeholder="Titulo"
              />
              <input
                className="editor__form__input"
                value={customLink}
                onChange={(e) => setCustomLink(e.target.value)}
                placeholder="Ej. https://www.mipagina.com/"
              />
              <button onClick={onAddCustomLink} className="editor__save__btn">
                {editCustom == -1 ? "Guardar" : "Guardar cambios"}
              </button>
              {editCustom !== -1 && (
                <button
                  onClick={onDeleteCustomLink}
                  className="editor__save__btn"
                >
                  Eliminar
                </button>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenTagLinks(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openTagLinks}
          >
            <div className="bottom__sheet__container">
              <h3>Conexiones</h3>
              <input
                className="editor__form__input"
                value={tagLinkName}
                onChange={(e) => setTagLinkName(e.target.value)}
                placeholder="Ej. Empresa, Proyecto, Colaboracion, etc."
              />
              <button onClick={onAddTag} className="editor__save__btn">
                Agregar
              </button>
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenVideoLinks(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openVideoLinks}
          >
            <div className="bottom__sheet__container">
              <h3>Videos</h3>
              <input
                className="editor__form__input"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
                placeholder="Ej. https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              />
              <button onClick={onAddVideoLink} className="editor__save__btn">
                {editVideo == -1 ? "Guardar" : "Guardar cambios"}
              </button>
              {editVideo !== -1 && (
                <button
                  onClick={onDeleteVideoLink}
                  className="editor__save__btn"
                >
                  Eliminar
                </button>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenAttachmentLinks(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openAttachmentLinks}
          >
            <div className="bottom__sheet__container">
              <h3>Adjuntos</h3>
              <input
                className="editor__form__input"
                value={attachmentLinkName}
                onChange={(e) => setAttachmentLinkName(e.target.value)}
                placeholder="Titulo"
              />
              <input
                className="editor__form__input"
                value={attachmentLink}
                onChange={(e) => setAttachmentLink(e.target.value)}
                placeholder="Ej. https://www.miarchivo.com/"
              />
              <button
                onClick={onAddAttachmentLink}
                className="editor__save__btn"
              >
                {editAttachment == -1 ? "Guardar" : "Guardar cambios"}
              </button>
              {editAttachment !== -1 && (
                <button
                  onClick={onDeleteAttachmentLink}
                  className="editor__save__btn"
                >
                  Eliminar
                </button>
              )}
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setOpenContactPhone(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
            open={openContactPhone}
          >
            <div className="bottom__sheet__container">
              <input
                className="editor__form__input"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                placeholder="Telefono"
              />
              <button
                onClick={onAddContactPhone}
                className="editor__save__btn"
              >
                Guardar
              </button>
            </div>
          </BottomSheet>
        </div>
      </div>
      {/*<Box sx={{ position: 'fixed', bottom: 4, right: 4 }}>
        <Fab color="primary" aria-label="add" onClick={() => history.push('/preview')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path d="M15.58 12c0 1.98-1.6 3.58-3.58 3.58S8.42 13.98 8.42 12s1.6-3.58 3.58-3.58 3.58 1.6 3.58 3.58Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-2.29-3.6-5.58-5.68-9.11-5.68-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19 2.29 3.6 5.58 5.68 9.11 5.68Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </Fab>
          </Box>*/}
      <Box sx={{ position: 'fixed', bottom: 4, right: 4 }}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            key={'preview'}
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path d="M15.58 12c0 1.98-1.6 3.58-3.58 3.58S8.42 13.98 8.42 12s1.6-3.58 3.58-3.58 3.58 1.6 3.58 3.58Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-2.29-3.6-5.58-5.68-9.11-5.68-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19 2.29 3.6 5.58 5.68 9.11 5.68Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            }
            tooltipTitle={'Vista Previa'}
            onClick={() => history.push('/preview')}
          />
          <SpeedDialAction
            key={'logout'}
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.9 7.56c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M15 12H3.62M5.85 8.65L2.5 12l3.35 3.35"></path></svg>}
            tooltipTitle={'Cerrar sesion'}
            onClick={handleClickOpenDialog}
          />
        </SpeedDial>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Seguro que deseas cerrar sesión?
        </DialogTitle>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={handleCloseDialog}>Cancelar</Button>
          <Button sx={{ textTransform: 'none' }} onClick={logout} autoFocus>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogLoading}
        onClose={handleCloseDialogLoading}
      >
        <DialogContent>
          <DialogContentText>
            <Box style={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
            <Box style={{ textAlign: 'center' }}>
              Cargando...
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Editor;
