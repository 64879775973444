import { useState } from "react";
import { useLoloContext } from "../../Context/appContext";
import validator from 'validator';
import { toast } from 'react-toastify';
import heroImage from './../../assets/authImage.jpeg'
import loloImg from "../../assets/lolo.png"
import "./Auth.css";

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { register, errors } = useLoloContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && email && password && confirmPassword) {
      if (!validator.isEmail(email)) {
        toast.error("Introduce un email valido")
        return
      }
      if (password !== confirmPassword) {
        toast.error("Contraseñas no coinciden")
        return
      }
      register(username, email, password);
    } else {
      toast.error("Llenar todos los campos")
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
      <img src={heroImage} className="auth-form-image" />
        <form onSubmit={handleSubmit} className="auth-form-content">
        <img src={loloImg} alt="lolo" className="auth-header-img" />
          <h4 className="title__text">Registrate y prueba LoLo</h4>
          <h4 className="title__text subtitle__text">Comienza hoy y comparte tu Perfil Digital</h4>
          <input placeholder="Nombre" onChange={(e) => setUsername(e.target.value)} />
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          <input placeholder="Contraseña" type='password' onChange={(e) => setPassword(e.target.value)} />
          <input placeholder="Confirmar Contraseña" type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
          <button className="btn__auth">Registrarse</button>
        </form>
      </div>
      
    </div>
  );
}

export default Signup;
