import { useState } from "react";
import { useLoloContext } from "../../Context/appContext";
import { toast } from 'react-toastify';
import heroImg from "../../assets/authImage.jpeg"
import loloImg from "../../assets/lolo.png"
import "./Auth.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, errors } = useLoloContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      login(email, password);
    } else {
      toast.error("Llenar todos los campos")
    }

  };
  return (
    <div className="auth-container">
      <div className="auth-form">
        <img src={heroImg} alt="" className="auth-form-image" />
        <form onSubmit={handleSubmit} className="auth-form-content">
          <img src={loloImg} alt="lolo" className="auth-header-img" />
          <h4 className="title__text">Inicio de Sesion</h4>
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          <input placeholder="Contraseña" type='password' onChange={(e) => setPassword(e.target.value)} />
          {errors && <h3 style={{ padding: "10px" }}>{errors}</h3>}
          <button type="submit" className="btn__auth">Iniciar Sesion</button>
          <a href="/reset-password" className="forget-password" >Olvide Contraseña</a>
        </form>

      </div>
    </div>
  );
}

export default Login;
