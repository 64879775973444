
import { getSocialIcon } from "../utils/getSocialIcons";

function SocialButtonLink({ id, social, link, onEditSocialLink }) {

  return (
    <button onClick={()=> onEditSocialLink(id)}  className="editor__button editor__button__small editor__button__gray">
      {getSocialIcon(social)}
    </button>
  );
}

export default SocialButtonLink;
