import * as React from 'react';
import { useLoloContext } from '../../Context/appContext';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toast } from 'react-toastify';
import { handleError } from '../../utils/utils';
import Stack from '@mui/material/Stack';

function UserDetailed({ match, history }) {
    const [user, setUser] = useState(null)
    const [value, setValue] = useState(null);
    const [cardColor, setCardColor] = useState("")
    const [category, setCategory] = useState("")
    const { logout, currentUser } = useLoloContext()
    const id = match.params.id;

    useEffect(() => {
        getUser()
    }, [id])

    const getUser = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            };
            const { data } = await axios.get(`${BASE_URL}/api/users/${id}`, config);
            setUser(data)
            setValue(data.subscriptionDate || null)
            setCardColor(data.cardColor)
            setCategory(data.category)

            console.log("User", data);
        } catch (error) {
            console.log("Error", error);
            handleError(error)
        }
    }

    const saveUser = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            };
            const { data } = await axios.put(
                `${BASE_URL}/api/users/${id}`,
                {
                    subscriptionDate: value,
                    cardColor,
                    category
                },
                config
            );
            toast.success("Actualizado correctamente")
        } catch (error) {
            handleError(error)
            console.log(error);
        }
    }


    if (!user) return <h3>Cargando..</h3>

    return (
        <React.Fragment>
            <Container>
                <Stack direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start" spacing={2}>
                    <h3>{user.name} {user.lastname}</h3>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Elegir fecha"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Color de Tarjeta"
                        value={cardColor}
                        onChange={(event) => {
                            setCardColor(event.target.value);
                        }}
                    />
                    <TextField
                        label="Categoria"
                        value={category}
                        onChange={(event) => {
                            setCategory(event.target.value);
                        }}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" onClick={saveUser}>Guardar</Button>
                        <Button variant="contained" onClick={() => history.goBack()}>Volver</Button>
                    </Stack>

                </Stack>
            </Container>
        </React.Fragment>
    );
}

export default UserDetailed;
