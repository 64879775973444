import { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactReadMoreReadLess from "react-read-more-read-less";
import PlusIcon from "../../icons/util/plus";
import { getSocialIcon } from "../../utils/getSocialIcons";
import { getContactIcon } from "../../utils/getContactIcons";
import "./Profile.css";
import ReactPlayer from "react-player";
import ModalImage from "react-modal-image";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ClipIcon from "../../icons/util/clip";
import { BASE_URL } from "../../Constants/Constants";
import { Container } from "@mui/system";
import { getContactLink } from "../../utils/utils";
import { useLoloContext } from "../../Context/appContext";
import { toast } from 'react-toastify';

function Preview({ match, history }) {
    const { currentUser } = useLoloContext();
    const [user, setUser] = useState(null);
    const [name, setName] = useState("");
    const [lastname, setLAstname] = useState("");
    const [email, setEmail] = useState("");
    const [position, setPosition] = useState("");
    const [company, setCompany] = useState("");
    const [bio, setBio] = useState("");
    const [selctedTab, sertSelectedTab] = useState("Negocios");

    const [error, setError] = useState(false);

    /**********Portada Image */
    const [portada, setPortada] = useState(
        "https://vc.taplolo.mx/lolo-assets/images/jorge-tapia-portada.png"
    );

    /**********Avatar Image */
    const [avatar, setAvatar] = useState(
        "https://vc.taplolo.mx/lolo-assets/images/jorge-tapia-avatar.jpeg"
    );

    /**********Contact LINKS */
    const [contactLinks, setContactLinks] = useState([]);

    /**********SOCIAL LINKS */
    const [socialLinks, setSocialLinks] = useState([]);

    /**********CUSTOM LINKS */
    const [customLinks, setCustomLinks] = useState([]);

    /**********TAGS LINKS */
    const [tagLinks, setTagLinks] = useState([]);

    /**********GALLERY LINKS */
    const [galleryLinks, setGalleryLinks] = useState([]);

    /**********VIDEO LINKS */
    const [videoLinks, setVideoLinks] = useState([]);

    /**********ATTACHMENTS LINKS */
    const [attachmentLinks, setAttachmentLinks] = useState([]);

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            };

            const { data } = await axios.get(`${BASE_URL}/api/users/data/me`, config);
            setName(data.name);
            setLAstname(data.lastname);
            setEmail(data.email);
            setPosition(data.position);
            setBio(data.bio);
            setCompany(data.company);
            //setPhone(data.contactPhone);
            setPortada(data.portada);
            setAvatar(data.avatar);
            setContactLinks(data.contactLinks);
            setSocialLinks(data.socialLinks);
            setCustomLinks(data.customLinks);
            setTagLinks(data.tagLinks);
            setGalleryLinks(data.galleryLinks);
            setVideoLinks(data.videoLinks);
            setAttachmentLinks(data.attachmentLinks);
            setUser(data);
            console.log("Me", data);
        } catch (error) {
            console.log("Error", error);
            setError(true)
        }
    }

    const shareClick = () => {
        navigator.clipboard.writeText(`${BASE_URL}/${user._id}`)
        toast.success("Copiado Correctamente")
    }

    const isTwoTypeSocial =
        socialLinks.filter(
            (ele, ind) =>
                ind ===
                socialLinks.findIndex((social) => social.socialType === ele.socialType)
        ).length > 1;
    if (error) return <Container sx={{ display: 'flex' }}>
        <h3>Error</h3>
    </Container>
    if (!user) return <Container sx={{ display: 'flex' }}>
        <CircularProgress />
    </Container>

    return (
        <div>
            <div className="profile">
                <div className="profile__container grid">
                    <div className="profile__portada">
                        <div>
                            <img src={portada} alt="" className="profile__portada__img" />
                        </div>
                        <div className="profile__data">
                            <div className="profile__perfil">
                                <img src={avatar} className="profile__avatar" alt="" />
                            </div>
                            <div className="profile__profile__text__container">
                                <h2 className="profile__name">{name}</h2>
                                <h2 className="profile__name">{lastname}</h2>
                                <h3 className="profile__profession">{company}</h3>
                                <h3 className="profile__company">{position}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="profile__body__container grid">
                        <div className="profile__info">
                            {/*<p className="profile__info-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut
              lectus et diam iaculis suscipit. Sed convallis molestie dui, nec
              porta dui auctor vitae. Curabitur condimentum, mauris a viverra
              dictum.
               </p>*/}
                            <ReactReadMoreReadLess
                                charLimit={180}
                                readMoreText={"Leer más"}
                                readLessText={"Leer menos"}
                                className="profile__info-description"
                                readMoreClassName="profile__readmore__readless"
                                readLessClassName="profile__readmore__readless"
                            >
                                {bio}
                            </ReactReadMoreReadLess>
                        </div>
                        <a href="#" className="profile__button profile__button__download">
                            <PlusIcon size="small" /> Guardar Contacto
                        </a>
                        <div className="profile__buttons">
                            {contactLinks.map((contact, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={getContactLink(contact.contactName, contact.contactLink)}
                                        className="profile__button profile__button__small profile__contact__button"
                                    >
                                        {getContactIcon(contact.contactName)}
                                    </a>
                                );
                            })}
                            <a
                                key={6789}
                                onClick={() => shareClick()}
                                className="profile__button profile__button__small profile__contact__button"
                            >
                                {getContactIcon("Compartir")}
                            </a>
                        </div>
                        <div className="profile__links__container grid">
                            <div style={{ margin: socialLinks.length > 0 ? "0 auto" : "0" }}>
                                {socialLinks.length > 0 &&
                                    (isTwoTypeSocial ? (
                                        <div className="tabConatiner">
                                            <div className="tabButtonsWrapper">
                                                <button
                                                    className={`tabButton ${selctedTab == "Negocios" && "selectedTab"
                                                        }`}
                                                    onClick={() => sertSelectedTab("Negocios")}
                                                >
                                                    <span>Negocios</span>
                                                </button>
                                                <button
                                                    className={`tabButton ${selctedTab == "Personal" && "selectedTab"
                                                        }`}
                                                    onClick={() => sertSelectedTab("Personal")}
                                                >
                                                    <span>Personal</span>
                                                </button>
                                            </div>
                                            <div className="tabContent">
                                                <div className="profile__buttons">
                                                    <div className="profile__buttons-small">
                                                        {socialLinks
                                                            .filter((soc) => soc.socialType == selctedTab)
                                                            .map((social, index) => {
                                                                return (
                                                                    <a
                                                                        key={index}
                                                                        href={social.socialLink}
                                                                        className="profile__button profile__button__small profile__button__gray"
                                                                    >
                                                                        {getSocialIcon(social.socialName)}
                                                                    </a>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="profile__buttons">
                                            <div className="profile__buttons-small">
                                                {socialLinks.map((social, index) => {
                                                    return (
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            key={index}
                                                            href={social.socialLink}
                                                            className="profile__button profile__button__small profile__button__gray"
                                                        >
                                                            {getSocialIcon(social.socialName)}
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                {customLinks.length > 0 && (
                                    <>
                                        <h3 className="profile__info__title">Enlaces</h3>
                                        <div className="profile__links__container grid">
                                            {customLinks.map((link, index) => {
                                                return (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={link.customLink}
                                                        className="profile__button profile__custom__links"
                                                    >
                                                        {link.customLinkName}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {tagLinks.length > 0 && (
                                    <>
                                        <h3 className="profile__info__title">Conexiones</h3>
                                        <div className="profile__tag__container">
                                            {tagLinks.map((tag, index) => (
                                                <div className="profile__tag">{tag.tagLinkName}</div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {galleryLinks.length > 0 && (
                                    <>
                                        <h3 className="profile__info__title">Galeria</h3>
                                        <div className="profile__links__container profile__gallery__wrapper grid">
                                            {galleryLinks.map((link, index) => {
                                                return (
                                                    <div className="profile__gallery__img__wrapper">
                                                        <ModalImage
                                                            small={link.galleryLink}
                                                            large={link.galleryLink}
                                                            alt="Gallery img"
                                                            hideDownload={true}
                                                            hideZoom={true}
                                                            className="profile__gallery__img"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {videoLinks.length > 0 && (
                                    <>
                                        <h3 className="profile__info__title">Videos</h3>
                                        <div className="profile__links__container grid">
                                            {videoLinks.map((link, index) => {
                                                return (
                                                    <div style={{ margin: "0 auto" }}>
                                                        <ReactPlayer
                                                            height={210}
                                                            width={320}
                                                            url={link.videoLink}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {attachmentLinks.length > 0 && (
                                    <>
                                        <h3 className="profile__info__title">Adjuntos</h3>
                                        <div className="profile__links__container grid">
                                            {attachmentLinks.map((link, index) => {
                                                return (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={link.attachmentLink}
                                                        className="profile__button profile__custom__links profile__attach__button"
                                                    >
                                                        <ClipIcon size="small" /> {link.attachmentLinkName}
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Preview;
