import EmailIcon from "../icons/contact/email";
import PhoneIcon from "../icons/contact/phone";
import ShareIcon from "../icons/contact/share";
import WhatsappIcon from "../icons/contact/whatsapp";

export const getContactIcon = (type) => {
    switch (type) {
      case "Email":
        return <EmailIcon />;
      case "Telefono":
        return <PhoneIcon />;
      case "Compartir":
        return <ShareIcon />;
      case "Whatsapp":
        return <WhatsappIcon />;
      default:
        return <WhatsappIcon />;
    }
  };