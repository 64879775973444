import { Box, Container } from "@mui/material";


function ErrorScreen() {
    return <Container sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ margin: 'auto' }} >
            Error
        </Box>

    </Container>
}

export default ErrorScreen