import FacebookIcon from "../icons/social/facebook";
import FlickrIcon from "../icons/social/flickr";
import InstagramIcon from "../icons/social/instagram";
import LinkedInIcon from "../icons/social/linkedIn";
import PinterestIcon from "../icons/social/pinterest";
import RedditIcon from "../icons/social/reddit";
import SnapchatIcon from "../icons/social/snapchat";
import SpotifyIcon from "../icons/social/spotify";
import TelegramIcon from "../icons/social/telegram";
import TwitterIcon from "../icons/social/twitter";
import YoutubeIcon from "../icons/social/youtube";
import TiktokIcon from "../icons/social/tiktok";

export const getSocialIcon = (type) => {
    switch (type) {
      case "Facebook":
        return <FacebookIcon />;
      case "Twitter":
        return <TwitterIcon />;
      case "Instagram":
        return <InstagramIcon />;
      case "Youtube":
        return <YoutubeIcon />;
      case "Linked In":
        return <LinkedInIcon />;
      case "Flickr":
        return <FlickrIcon />;
      case "Pinterest":
        return <PinterestIcon />;
      case "Reddit":
        return <RedditIcon />;
      case "Snapchat":
        return <SnapchatIcon />;
      case "Spotify":
        return <SpotifyIcon />;
      case "Telegram":
        return <TelegramIcon />;
      case "Tik Tok":
        return <TiktokIcon />;
      default:
        return <FacebookIcon />;
    }
  };