import * as React from 'react';
import { useLoloContext } from '../../Context/appContext';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';

function Home({ match, history }) {
    const { logout, currentUser } = useLoloContext()
    return (
        <React.Fragment>
            <Container>
                {currentUser ?
                    (<><Button
                        variant="contained"
                        color="primary"
                        onClick={logout}>Cerrar Sesion</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push("/editor")}>Editor</Button></>) : null}
                {!currentUser ? (<><Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/login")}>Log in</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push("/signup")}>Registrarse</Button></>) : null}

            </Container>
        </React.Fragment>
    );
}

export default Home;
