export default function PlusIcon({size = "large"}) {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88 88"
      width={size == "small" ? "20" : "40"}
      height={size == "small" ? "20" : "40"}
    >
        <g id="Camera">
      <path d="M12,28h6a1,1,0,0,0,0-2H12a1,1,0,0,0,0,2Z"/><path d="M10,34H23a6,6,0,0,0,6-6V26a6,6,0,0,0-6-6H7a6,6,0,0,0-6,6V43A9,9,0,0,1,10,34Zm2-10h6a3,3,0,0,1,0,6H12a3,3,0,0,1,0-6Z"/><path d="M62.93,10.63A1,1,0,0,0,62,10H38a1,1,0,0,0-.93.63L34.12,18H65.88Z"/><path d="M79,16a1.0029,1.0029,0,0,0-1-1H70a1.0029,1.0029,0,0,0-1,1v2H79Z"/><path d="M50,70A18,18,0,1,0,32,52,18.0207,18.0207,0,0,0,50,70Zm0-32A14,14,0,1,1,36,52,14.0154,14.0154,0,0,1,50,38Z"/><path d="M50,64A12,12,0,1,0,38,52,12.01,12.01,0,0,0,50,64Zm0-20a1,1,0,0,1,0,2,6.0048,6.0048,0,0,0-6,6,1,1,0,0,1-2,0A8.0106,8.0106,0,0,1,50,44Z"/><path d="M31,26H87a6.0048,6.0048,0,0,0-6-6H28.28A7.9647,7.9647,0,0,1,31,26Z"/><path d="M23,36a3.9989,3.9989,0,0,0-4,4V77a2.7719,2.7719,0,0,1-.18,1H82a5.0018,5.0018,0,0,0,5-5V28H31A8.0106,8.0106,0,0,1,23,36ZM81,74H69a1,1,0,0,1,0-2H81a1,1,0,0,1,0,2Zm-6-6h6a1,1,0,0,1,0,2H75a1,1,0,0,1,0-2ZM50,32A20,20,0,1,1,30,52,20.02,20.02,0,0,1,50,32Z"/><path d="M3,43V72a6,6,0,0,0,6,6h7a1.0029,1.0029,0,0,0,1-1V40a5.9306,5.9306,0,0,1,1.54-4H10A7,7,0,0,0,3,43Z"/></g>{" "}
    </svg>
    );
  }
  